import React, { useState, useEffect, useRef } from "react";
import {
  makeStyles,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  Paper,
  Button,
  TextField,
  Divider,
  Grid,
  IconButton,
  Drawer,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Header from "../../components/navbars/topNavbar";
import withAllContexts from "../../HOCs/withAllContexts";
import { v4 as uuidv4 } from "uuid";
import { LanguageContext } from "../../contexts";
// import { MTableEditRow } from "material-table";
import axios from "axios";
import { config } from "../../config";
// import Alert from "../../components/alert";
import { ReactTransliterate } from "react-transliterate";
import "react-transliterate/dist/index.css";
import { isDefinitionNode } from "graphql";
import Dialog from "../../components/dialog/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  createDocument,
  deleteDocument,
  updateDocument,
} from "../../apis/crud";
import isEqual from "react-fast-compare";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#f4f4f5",
  },
  container: {
    padding: "25px",
  },
  tableRow: {
    height: 50,
  },
  tabAlign: {
    display: "flex",
    paddingBottom: "16px",
  },
  drawerContainer: {
    padding: "18px",
  },
  drawerContent: {
    paddingTop: "16px",
  },
}));

const drawerPref = [
  { titles: "Title", placeholder: "User", label: "User", minWidth: "50px" },
  {
    titles: "English",
    placeholder: "Enter your text",
    label: "language",
    minWidth: "180px",
  },
  {
    titles: "French",
    placeholder: "Enter your text",
    label: "Enter your text",
    minWidth: "180px",
  },
  {
    titles: "Spanish",
    placeholder: "Enter your text",
    label: "Enter your text",
    minWidth: "180px",
  },
];
const taste = [
  { no: "1", name: "lane", active: "active" },
  { no: "2", name: "dane", active: "active" },
  { no: "3", name: "pane", active: "active" },
  { no: "4", name: "fane", active: "active" },
];

// const drawerPref = [
//   { titles: "Title", placeholder: "User", label: "User", minWidth: "50px" },
//   {
//     titles: "English",
//     placeholder: "Enter your text",
//     label: "language",
//     minWidth: "180px",
//   },
//   {
//     titles: "French",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
//   {
//     titles: "Spanish",
//     placeholder: "Enter your text",
//     label: "Enter your text",
//     minWidth: "180px",
//   },
// ];

function MessageCatalog(props) {
  const classes = useStyles();
  const messageHeaderName = "QDM";

  const [fieldChange, setFieldChange] = useState({});
  const [languageDetails, setLanguageDetails] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const [isdrawerOpen, setDraweropen] = useState(false);
  const [isEditOpen, setEditOpen] = useState({
    field: "",
    isOpen: false,
  });
  const languageDetailsRef = useRef([]);
  const [drawerPref, setDrawerPref] = useState([
    {
      titles: "Title",
      placeholder: "User",
      label: "User",
      minWidth: "50px",
      code: "",
    },
  ]);
  const [editedLanguage, setEditedLanguage] = useState({});
  const [messageAlert, setMessageAlert] = useState({
    isopen: false,
    snackmsg: "client save successfully",
    snacktype: "success",
  });
  const editOpen = (languages, index) => {
    languages = JSON.parse(JSON.stringify(languages));

    // console.log("current index", index);
    debugger;
    setEditOpen((prev) => {
      return { ...prev, isOpen: true, field: languages };
    });
    setFieldChange({
      ...languages,
    });
  };
  const editClose = (languages) => {
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, field: "" };
    });
  };

  useEffect(() => {
    if (window.location.search && window.location.search.length > 0) {
      let params = new URLSearchParams(window.location.search);
      localStorage.setItem("metadataId", params.get("metadata_id"));
      localStorage.setItem("projectName", params.get("projectName"));
    }
    getlanguage();
    getMessageLog();
  }, []);
  const getlanguage = async () => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: "projectvstools",
        filter: `projectvstools.metadataid=='${localStorage.getItem(
          "metadataId"
        )}'`,
        return_fields:
          "merge(keep(projectvstools,'projectid','clientid'),{projectlang:document(document(projectvstools.projectid).projectlang)})",
      })
      .then((response) => {
        if (response.data.result[response.data.result.length - 1].projectlang) {
          let selectLanguage =
            response.data.result[response.data.result.length - 1].projectlang;
          setProjectDetails({
            clientid:
              response.data.result[response.data.result.length - 1].clientid ??
              "",
            projectid:
              response.data.result[response.data.result.length - 1].projectid ??
              "",
          });
          console.log(selectLanguage);
          let generateJSON = selectLanguage.map((data, index) => {
            return {
              titles: data.description,
              placeholder: "Enter your text",
              label: "language",
              minWidth: "180px",
              code: data.code,
            };
          });
          // let generateJSON =[
          //   {...selectLanguage,titles: selectLanguage.language,placeholder: "Enter your text",label: "language", minWidth: "180px",code:selectLanguage.code}
          // ]
          setDrawerPref([...drawerPref, ...generateJSON]);
          props.backDrop.setBackDrop({
            open: false,
            message: "",
          });
        }
      })
      .catch((err) => {});
  };

  const getMessageLog = async () => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });
    await axios
      .post(`${config.arango_api_url}/read_documents`, {
        db_name: `${config.database}`,
        entity: `message_catalog`,
        filter: `message_catalog.metadataid == '${localStorage.getItem(
          "metadataId"
        )}' && message_catalog.activestatus==true`,
        return_fields: `message_catalog`,
      })
      .then((response) => {
        debugger;
        if (response.data.result.length > 0) {
          let resultData = response.data.result;
          languageDetailsRef.current = response.data.result;
          let result = resultData.reduce((lngs, lng) => {
            let lngObj = {
              Title: lng?.title,
              id: lng?.id,
              ...lng?.language,
            };
            return [...lngs, lngObj];
          }, []);
          setLanguageDetails(result);
        }
      })
      .catch((err) => {});
    props.backDrop.setBackDrop({
      open: false,
      message: "",
    });
  };
  const drawerClosing = () => {
    setDraweropen(false);
  };
  const drawerOpen = () => {
    let fields = {};
    drawerPref.map((field) => {
      fields[field.titles] = "";
    });
    setFieldChange({
      ...fieldChange,
      ...fields,
    });
    setDraweropen(true);
  };

  const fieldChanging = (e) => {
    const fieldName = e.name;
    const fieldValue = e.value;
    setFieldChange((pre) => {
      return {
        ...pre,
        id: uuidv4(),
        [fieldName]: fieldValue,
      };
    });
  };

  const onAdd = async () => {
    let countList = Object.values(fieldChange).filter(
      (data) => data.length > 0
    );
    if (countList.length < 2) {
      props.alert.setSnack({
        open: true,
        msg: "Please Fill the  Data",
        severity: "info",
      });
      return;
    }
    setLanguageDetails((pre) => {
      return [...pre, fieldChange];
    });
    setDraweropen(false);
  };

  const [deleteState, setDeleteState] = useState({});
  const [deleteDrawer, setDeleteDrawer] = useState(false);
  const deleteRow = (languages) => {
    const newRow = languageDetails.filter((itm) => itm.id !== languages.id);
    setLanguageDetails(newRow);
    handleDeleteDrawerClose();
  };
  const handleDelete = (lang) => {
    setDeleteState(lang);
    setDeleteDrawer(true);
  };
  const handleDeleteDrawerClose = () => {
    setDeleteDrawer(false);
  };
  const onEditing = (e) => {
    const editname = e.name;
    const editvalue = e.value;
    // setEditedLanguage((preve) => {
    //   return {
    //     ...preve,
    //     [editname]: editvalue,
    //   };
    // });
    let editField = isEditOpen;
    editField.field[editname] = editvalue;
    debugger;
    setEditOpen({
      ...editField,
    });
  };

  const onEditAdd = () => {
    debugger;
    // languageDetails[
    //   languageDetails
    //     .map((x, i) => [i, x])
    //     .filter((x) => x[1] == isEditOpen.field)[0][0]
    // ] = isEditOpen.field;
    // saveLanguageDetails();
    let _languageDetails = languageDetails?.map((_d) => {
      if (isEditOpen?.field?.id && _d?.id === isEditOpen?.field?.id) {
        _d = isEditOpen?.field;
      }
      return _d;
    });
    setLanguageDetails(_languageDetails);
    setEditOpen((prev) => {
      return { ...prev, isOpen: false, field: "" };
    });
  };
  const saveLanguageDetails = async () => {
    props.backDrop.setBackDrop({
      open: true,
      message: "loading",
    });

    let allPromises = [];
    //finding the deleted documents
    languageDetailsRef.current.forEach((msg) => {
      console.log(msg, "lngDetails");
      if (!languageDetails.some((message) => message.id === msg?.id)) {
        let result = {
          entity: "message_catalog",
          filter: {
            id: msg.id,
          },
        };
        let msgDeleteRequest = deleteDocument([result]);
        allPromises.push(msgDeleteRequest);
      }
    });
    //finding the created & updated documents
    let createdMsgCatalogs = [];
    let updatedMsgCatalogs = [];

    languageDetails.map((msg) => {
      let result = languageDetailsRef.current.find(
        (message) => message.id === msg?.id
      );
      if (result) {
        let { Title, id, ...remaining } = msg;
        let createdSchema = {
          clientid: projectDetails.clientid,
          projectid: projectDetails.projectid,
          id: msg?.id,
          metadataid: localStorage.getItem("metadataId"),
          language: remaining,
          title: msg?.Title,
        };
        updatedMsgCatalogs.push({
          entity: "message_catalog",
          body: createdSchema,
          filter: {
            id: msg.id,
          },
        });
      } else {
        let { Title, id, ...remaining } = msg;
        let updatedSchema = {
          clientid: projectDetails.clientid,
          projectid: projectDetails.projectid,
          id: msg?.id,
          metadataid: localStorage.getItem("metadataId"),
          language: remaining,
          title: msg?.Title,
        };
        createdMsgCatalogs.push({
          entity: "message_catalog",
          body: updatedSchema,
        });
      }
    });

    if (createdMsgCatalogs.length > 0) {
      let createdMsgCatalogRequest = createDocument(createdMsgCatalogs);
      allPromises.push(createdMsgCatalogRequest);
    }
    if (updatedMsgCatalogs.length > 0) {
      let updatedMsgCatalogRequest = updateDocument(updatedMsgCatalogs);
      allPromises.push(updatedMsgCatalogRequest);
    }

    Promise.all(allPromises)
      .then(async (result) => {
        console.log("result:\n" + JSON.stringify(result));
        props.alert.setSnack({
          open: true,
          msg: "Message catalog save successfully",
          severity: "success",
        });
      })
      .catch((err) => {
        console.error("Failed to do the operations");
        console.error(err);
        props.alert.setSnack({
          open: true,
          msg: "Message catalog save faild !",
          severity: "error",
        });
      });
    props.backDrop.setBackDrop({
      open: false,
      message: "",
    });
  };
  // const closeSnackBar = () =>{
  //   setMessageAlert({
  //     ...messageAlert,
  //     isopen:false
  //   })
  // }
  const drawerClose = () => {
    setEditOpen({
      ...isEditOpen,
      isOpen: false,
    });
  };
  const closeIsDrawerOpen = () => {
    setDraweropen(false);
  };
  const [loader, setLoader] = useState(true);
  const closeLoader = () => {
    setLoader(false);
  };
  return (
    <div className={classes.root}>
      {/* <Dialog 
      open={loader}
      handleClose={()=>closeLoader()}
      transprant={true}
      component={
        <CircularProgress color="secondary" style={{margin: "auto"}}/>
      }
      /> */}
      <Dialog
        open={deleteDrawer}
        handleClose={() => handleDeleteDrawerClose()}
        component={
          <div>
            <div style={{ padding: "10px", textAlign: "center" }}>
              <Typography
                className={classes.title}
                style={{ fontSize: "1.2rem" }}
              >
                Are you sure, you want to delete?
              </Typography>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "15px",
                padding: "10px",
              }}
            >
              <Button
                variant="contained"
                style={{ marginRight: "10px" }}
                onClick={() => handleDeleteDrawerClose()}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => deleteRow(deleteState)}
              >
                Submit
              </Button>
            </div>
          </div>
        }
      />
      {/* {messageAlert.isopen && (
          <Alert
            severity={messageAlert.snacktype}
            open={messageAlert.isopen}
            vertical={"top"}
            horizontal={"right"}
            msg={messageAlert.snackmsg}
            onclose={() => closeSnackBar()}
          />
        )} */}
      <Header headerName={messageHeaderName}></Header>
      <div className={classes.container}>
        <div className={classes.tabAlign}>
          <Typography style={{ fontSize: "24px", flexGrow: 1 }}>
            Message Catalog
          </Typography>
          <Button
            onClick={drawerOpen}
            variant="contained"
            style={{ marginRight: "10px" }}
          >
            add
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => saveLanguageDetails()}
          >
            Save
          </Button>
        </div>
        <Paper>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {drawerPref.map((itm) => (
                    <TableCell style={{ width: itm.minWidth }}>
                      {itm.titles}
                    </TableCell>
                  ))}
                  <TableCell align="left">Actions</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {languageDetails?.length <= 0 ? (
                  <>
                    <TableRow height="200">
                      <div
                        style={{
                          position: "absolute",
                          width: "92%",
                          textAlign: "center",
                          marginTop: "30px",
                        }}
                      >
                        <span>No Data Found</span>
                      </div>
                    </TableRow>
                  </>
                ) : (
                  ""
                )}
                {languageDetails.map((languages, index) => (
                  <TableRow className={classes.tableRow}>
                    {Object.keys(languages).map((key) =>
                      key !== "id" ? (
                        <TableCell>{languages[key]}</TableCell>
                      ) : (
                        ""
                      )
                    )}
                    {/* <TableCell>{languages.Title}</TableCell>
                    <TableCell>{languages.English}</TableCell>
                    <TableCell>{languages.French}</TableCell>
                    <TableCell>{languages.Spanish}</TableCell> */}
                    <TableCell
                      align="left"
                      style={{ display: "flex", padding: "0px !important" }}
                    >
                      <div style={{ marginLeft: "-18px" }}>
                        <IconButton onClick={(e) => editOpen(languages, index)}>
                          <EditIcon></EditIcon>
                        </IconButton>
                        <IconButton onClick={(e) => handleDelete(languages)}>
                          <HighlightOffIcon></HighlightOffIcon>
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </div>

      {/* ----------------------------------- Drawer ----------------------------------------------------- */}
      <Drawer
        open={isdrawerOpen}
        onClose={() => closeIsDrawerOpen()}
        anchor="right"
      >
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            Add language preferences
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  {/* <TextField
                    size="small"
                    variant="outlined"
                    placeholder={item.placeholder}
                    label={item.placeholder}
                    name={item.titles}
                    onChange={fieldChanging}
                    fullWidth
                  ></TextField> */}
                  <ReactTransliterate
                    value={fieldChange[item.titles]}
                    onChangeText={(_data) => {
                      fieldChanging({ name: item.titles, value: _data });
                    }}
                    lang={item.code}
                    offsetY={5}
                    style={{
                      boxShadow: "0 0 12px 0 #eee",
                      color: "rgba(0,0,0,.8)",
                      fontSize: "1rem",
                      border: "1px solid rgb(156 156 156)",
                      outline: "none",
                      width: "200px",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={drawerClosing}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onAdd}>
              Add
            </Button>
          </div>
        </div>
      </Drawer>

      {/* ============================ Edit Drawer ===================================== */}
      <Drawer
        open={isEditOpen.isOpen}
        onClose={() => drawerClose()}
        anchor="right"
      >
        <div className={classes.drawerContainer}>
          <Typography
            style={{ fontSize: "18px", fontWeight: "500", padding: "10px 0px" }}
          >
            Edit language preferences
          </Typography>
          <Divider></Divider>
          {drawerPref.map((item) => (
            <div className={classes.drawerContent}>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div style={{ display: "flex", padding: "10px 0 0 0" }}>
                    <Typography>{item.titles}</Typography>
                    <div style={{ flexGrow: 1 }}></div>
                    <Typography>:</Typography>
                  </div>
                </Grid>
                <Grid item xs={8} style={{ margin: "auto" }}>
                  {/* <TextField
                    size="small"
                    variant="outlined"
                    placeholder={item.placeholder}
                    label={item.placeholder}
                    name={item.titles}
                    onChange={onEditing}
                    value={isEditOpen.field[item.titles]}
                    fullWidth
                  ></TextField> */}
                  <ReactTransliterate
                    value={isEditOpen.field[item.titles]}
                    onChangeText={(_data) => {
                      onEditing({ name: item.titles, value: _data });
                    }}
                    // onChange={(e)=>{e.target.name=item.titles;onEditing(e)}}
                    lang={item.code}
                    offsetY={5}
                    style={{
                      boxShadow: "0 0 12px 0 #eee",
                      color: "rgba(0,0,0,.8)",
                      fontSize: "1rem",
                      border: "1px solid rgb(156 156 156)",
                      outline: "none",
                      width: "200px",
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          ))}
          <div
            style={{
              width: "80%",
              margin: "auto",
              marginTop: "15px",
              textAlign: "end",
            }}
          >
            <Button
              variant="contained"
              onClick={editClose}
              style={{ marginRight: "10px" }}
            >
              Close
            </Button>
            <Button variant="contained" color="primary" onClick={onEditAdd}>
              Update
            </Button>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default withAllContexts(MessageCatalog);
