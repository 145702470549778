import React from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles,Typography, AppBar,Grid,Divider,Avatar,IconButton } from '@material-ui/core'

// style
const styles = theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    opacity: 1
  },
  flexCenter:{
    display:'flex',
    alignItems:'center',
    flex:1
  },
  divider:{
    height:30,
    alignSelf:'center',
    margin:'0px 15px',
    backgroundColor:'#fff'
  },
  logo:{
    width:100
  },
  container:{
    padding:theme.spacing(2)
  },
  appbar: {
    width: '100%',
  },
  userLogo:{
    marginRight:15
  },
  logoutDiv:{
    '& .MuiAvatar-root':{
      width:20,
      height:20
    }
  }
})

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

//   onLogout = ()=>{
//     sessionStorage.clear();
//     this.props.history.push('/login')
//   };

  render () {
    const { classes } = this.props
    // const location = window.location.pathname

    return (
      <div className={classes.root}>
        {/* AppBar Component in Top navar */}
        <AppBar position='static' className={classes.appbar}>
            <Grid
              container
              direction="row"
              alignItems="center"
              className={classes.container}
              >

                {
                  <>
                  <Grid item className={classes.flexCenter}>
                    {
                   
                      <>
                        <img alt='img' src='/assets/01_AINQA_Logo_Horizontal_White.svg' className={classes.logo} />
                        <Divider orientation="vertical" flexItem className={classes.divider} />
                      </>
                    }
                    <Typography variant='h6'>{'Message Catalog'}</Typography>
                </Grid>
                <Grid item className={classes.userLogo}>
                  <Avatar alt="logo" src="/assets/profile.svg" />
                </Grid>
                {/* <Grid item className={classes.logoutDiv}>
                <abbr title="LogOut">
                  <IconButton size="small">
                    
                      <Avatar
                        src='/assets/sign-out.svg'
                        className={classes.logout}
                        onClick={()=>this.onLogout()}
                      />
              
                </IconButton>
                </abbr>
                </Grid> */}
                
                </>
                }
            </Grid>
        </AppBar>
        {/* end */}
      </div>
    )
  }
}

export default withStyles(styles)(withRouter(Header))
